/**
 * LoginPage
 *
 * 1. Bootstrap gutter size.
 */
body.home {
  background: $gray-light center url('../../images/login-bg.jpg') no-repeat;
  background-size: cover;

  .SiteLayout-main {
    background: none;
  }

  .SiteFooter-links {
    padding: 0 2em;

    .LinkList-link {
      font: 14pt $font1;
      color: $gray-dark;
      margin-left: 1.5em;
    }
  }
}

.LoginForm-form {
  .form-group {
    margin-bottom: 1.4rem;
  }

  .label,
  .form-control {
    font: bold 14pt $font1;
  }

  .form-control {
    line-height: 36px;
  }

  .row-buttons {
    padding-top: 10px;

    a {
      font: bold 14pt $font1;
      color: $gray-dark;
    }
  }

  .btn-lg {
    min-width: 14em;
  }
}

.welcome-text {
  font: 16pt $font1;
  line-height: 1.5em;
  padding: 9px 0 20px;
}

// homepage layout
.home {
  .SiteFooter {
    background: transparent;
  }
}

.LoginPage {
  padding-top: 2.6em;
}

.LoginPage-section {
  background-color: $white;
  padding: 2.4rem;
  max-width: 53.5%;
  margin-left: auto;
}

.LoginPage-title {
  color: $primary;
  font: 1.66rem $font2;
  margin-bottom: 1rem;
  font-weight: bold;
}

.PasswordPage {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.PasswordPage-card {
  background-color: $white;
  border-radius: $border-radius;
  grid-column: 1 / 4;
  padding: 2rem;

  @include media-breakpoint-up(lg) {
    grid-column: 2 / 3;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  body.home {
    .SiteFooter-links {
      .LinkList-link {
        font-size: 12pt;
        margin-left: 1em;
      }
    }
  }

  .LoginForm-form {
    .form-group {
      margin-bottom: 1rem;
    }

    .label,
    .form-control {
      font-size: 12pt;
    }

    .form-control {
      line-height: 28px;
    }

    .row-buttons {
      a {
        font-size: 12pt;
      }
    }

    .btn-lg {
      min-width: 10em;
      font-size: 12pt;
    }
  }

  .welcome-text {
    font-size: 12pt;
    line-height: 1.25em;
  }

  .LoginPage {
    padding-top: 2em;
  }

  .LoginPage-section {
    max-width: 65%;
    padding: 1.5rem;
  }

  .LoginPage-title {
    font-size: 14pt;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  body.home {
    .SiteFooter-links {
      .LinkList-link {
        font-size: 10pt;
      }
    }
  }

  .LoginForm-form {
    .label,
    .form-control {
      font-size: 10pt;
    }

    .form-control {
      line-height: 24px;
    }

    .row-buttons {
      a {
        font-size: 10pt;
      }
    }

    .btn-lg {
      min-width: 100%;
      font-size: 10pt;
    }
  }

  .welcome-text {
    font-size: 10pt;
    padding-bottom: 9px;
  }

  .LoginPage-section {
    padding: 1.2rem;
    max-width: 100%;
  }

  .LoginPage-title {
    font-size: 1rem;
  }
}
