// common
$enable-shadows: true;

// colors
$primary: $blue-dark;
//  $secondary:     $gray-600 !default;
//  $success:       $green !default;
//  $info:          $cyan !default;
//  $warning:       $yellow !default;
//  $danger:        $red !default;
//  $light:         $gray-100 !default;
//  $dark:          $gray-800 !default;
$body-bg: $blue-dark;

// navbar
$navbar-light-color: $white;
$navbar-padding-x: 0;
$nav-pills-link-active-bg: $white;
$nav-pills-link-active-color: $blue-dark;

// fonts
$font-family-base: $font1;

// headers
$headings-color: $gray-dark;
$headings-line-height: 1.14;
$headings-font-family: $font-family-base;
$h3-font-size: 1rem;

// forms
$input-border-radius: 0;
$input-color: $blue-dark;
$input-placeholder-color: $blue-dark;
$input-btn-border-width: 0;
$input-btn-focus-box-shadow: none;
$input-box-shadow: none;
$btn-box-shadow: none;
$btn-active-box-shadow: none;

$custom-select-border-radius: initial;
$custom-select-focus-border-color: transparent;
$custom-select-focus-box-shadow: initial;
$custom-select-padding-y: .4375rem;

// cards
$card-border-radius: .625rem;
$card-border-width: 0;

// table
//$table-cell-padding: 0.625rem;
$table-accent-bg: #e0f2ff;
$table-hover-bg: #a3d7ff;
