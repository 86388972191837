/**
 * SiteFooter
 */

$site-footer-background-color: $white;
$site-footer-padding: 1rem;

.SiteFooter {
  background-color: $site-footer-background-color;
  font-size: .875rem;
  padding: $site-footer-padding 0;
}

.SiteFooter-inner {
  display: flex;
  align-items: center;
}

.SiteFooter-logo {
  display: none;

  @include media-breakpoint-up(md) {
    display: initial;
    margin-right: 1em;
  }
}

.SiteFooter-logo > img {
  height: 2em;
}

.SiteFooter-links {
  margin-left: auto;
}
