.profile-page .avatar {
  width: 10em;
}

#profile-image .image {
  height: 10em;
  margin-left: auto;
  margin-right: auto;
}

#clinic-departments .panel-group {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

#clinic-departments .filterblock {
  display: flex;
  margin: 2%;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  #clinic-departments .filterblock {
    margin: 2% 5%;
  }
}

.clinic_adress_booking_modal .modal-dialog {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  .wrapper-departaments-block {
    width: 90%;

  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .wrapper-departaments-block {
    margin: 1em 5% !important;
    width: 40%;

  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .wrapper-departaments-block {
    width: 44%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .wrapper-departaments-block {
    width: 29%;

  }
}

@media screen and (min-width: 1200px) {
  .wrapper-departaments-block {
    width: 29%;
  }
}

.wrapper-departament-element {
  background-color: $white;
  padding: .5em;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid $blue-dark;
  margin-bottom: .5em;
  margin-left: .5em;
  width: 24%;
}

@include media-breakpoint-down(md) {
  .wrapper-departament-element {
    width: 100%;
  }

  .contact-list-item .contact-detail .contact-content
  .contact-list-item .contact-detail .contact-content .ewp-data-contact-email,
  .contact-list-item .contact-detail .contact-content .contact-position,
  .contact-list-item .contact-detail .contact-content .ewp-contact-phoneNumber {
    white-space: nowrap;
  }

}

.contact-detail {
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.contact-detail .contact-avatar {
  height: 2em;
  width: 2em;
  min-width: 2em;
}

.profile-bottom .app_dashboard::before,
.profile-bottom .app_cases_list::before {
  border: 1px solid #20699c;
}

.ewp-contact-contact {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-width: 100%;
}

.ewp-contact-phoneNumber,
.ewp-data-contact-email {
  max-width: 100%;
}

.profile-page .profile-bottom {
  float: left;
  width: 100%;
}

.profile-page input[value='save'] {
  float: left;
  margin-top: 4em;
}

.ewp-modal-profile-image-edit {
  margin-bottom: 1em;
}

.ewp-file-upload .ewp-file-input-button {
  background-color: #20699c;
  border-color: #20699c;
}

#clinic-departments .department-headline {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}

#clinic-departments .panel-heading {
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  margin-bottom: 5px;
}

#clinic-departments .panel-heading .panel {
  padding: 5px;
}

#clinic-departments .panel-heading .panel .department-name {
  font-weight: bold;
  color: $gray-dark;
}

#clinic-departments .panel-body {
  padding-top: 1px;
  padding-left: 0;
  padding-right: 0;
  overflow-wrap: break-word;
}

#clinic-departments .panel-body .contact-list-item i {
  padding-right: 5px;
  cursor: pointer;
}

#clinic-departments .panel-body .contact-list-item .contact-name,
#clinic-departments .panel-body .contact-list-item .ewp-data-contact-email,
#clinic-departments .panel-body .contact-list-item .ewp-contact-phoneNumber {
  font-size: 14px;
  padding-bottom: 1px;
  max-width: 100%;
  color: $blue-dark;

}

#clinic-departments .panel-body .contact-list-item .contact-content {
  max-width: 85%;
  font-size: 12px;
  padding-left: 10px;
  color: #2c96f4;
}

#clinic-departments .panel-body .contact-list-item .contact-content .contact-position {
  font-size: 14px;
  padding-bottom: 5px;
  color: $blue-dark;
  font-weight: bold;
}

#clinic-departments .panel-body .contact-list-item .contact-content .contact-email {
  font-size: 12px;
}

#clinic-departments .panel-body .contact-list-item .contact-content .contact-email > a {
  color: #20699c;
}

.contact-list-item .contact-detail .contact-content,
.contact-list-item .contact-detail .contact-content .ewp-data-contact-email,
.contact-list-item .contact-detail .contact-content .contact-position,
.contact-list-item .contact-detail .contact-content .ewp-contact-phoneNumber {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-list-item .contact-detail .contact-content .contact-name,
.contact-list-item .contact-detail .contact-content .contact-position,
.contact-list-item .contact-detail .contact-content .ewp-contact-phoneNumber {
  cursor: default;
}

.contact-list-item .contact-detail .contact-content .ewp-data-contact-email {
  cursor: pointer;
}

.name {
  font-weight: bold;
}

.buchugen_service_modal .modal-dialog {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@include media-breakpoint-down(md) {
  .buchugen_service_modal .modal-dialog {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.patient-discharges-list-for-day .modal-dialog {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@include media-breakpoint-down(md) {
  .patient-discharges-list-for-day .modal-dialog {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.standesort {
  font-size: 1rem;
  color: $blue-dark;
  font-weight: bold;
}

.clinic_id {
  display: none;
}

.clinic_name {
  font-size: 1rem;
  color: $blue-dark;
  font-weight: bold;
}


@media screen and (min-width: 480px) {
  div.radio > label.frequency_daily {
    padding-right: 100px !important;
  }

}

div.radio > label.frequency_weekly {
  padding-right: 100px !important;
}

div.notificationType > div.radio {
  flex: 0 0 50%;
  max-width: 50%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

label.unbold {
  font-weight: normal;
}

div.radio > label {
  font-weight: normal;
}


i.form-collection-add,
i.form-collection-delete {
  cursor: pointer;
  color: $blue;
  font-size: 1.5em;
}

.form-collection-content-row {
  .form-group-collection,
  .form-group-first {
    > div {
      margin-bottom: 1em;
    }

    .form-group {
      float: left;
      width: 16em;
      margin-right: 1em;

      &:last-child {
        margin-right: .5em;
      }
    }

    .form-collection-delete {
      margin-top: 1.5em;
      margin-left: .5em;
      color: $blue;
    }
  }
}
