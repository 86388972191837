/**
 * SiteLayout
 *
 * Basic layout of the site with sticky footer.
 *
 * 1. Should be on top of DataTables ui elements.
 */

.SiteLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: $gray-dark;
}

.SiteLayout-header {
  height: $site-header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2; /* 1 */
}

.SiteLayout-main {
  background-color: $primary;
  flex: 1;
  margin-top: $site-header-height + 1rem;
}

.SiteLayout-sidebar {
  overflow-x: hidden;

  @include media-breakpoint-up(sm) {
    position: sticky;
    top: $site-header-height + 1rem;
  }
}

.clinic-contact-help {
  margin: -1rem 0 1rem 1.2rem;
  font-size: .75rem;
}

.tumorboard-contact {
  font-size: .9rem;

  .row-line {
    padding-bottom: .25rem;
  }

  .name {
    font-weight: bold;
  }

  i.fa {
    margin-right: .6rem;
    font-size: 1rem;
  }
}

.tumorboard-graphical-material {
  padding-top: 2.5em;

  .material-item {
    padding: 0 1em 0 .5em;
  }

  .item-input {
    vertical-align: 2px;
  }
}

.non-clickable {
  cursor: default;
}
